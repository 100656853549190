import useMediaQuery from 'components/hooks/useMediaQuery'
import Link from 'next/link'
import React from 'react'

const GatsuurhanHeader = ({ data }) => {
  if (!data) return null
  const isMobile = useMediaQuery('(max-width: 620px)')

  const headerBanner = data.find(
    (banner) => banner?.code === 'Blackfriday-header'
  )
  if (!headerBanner) return null

  const headerImg = headerBanner?.translations?.mn_MN?.imageName
  const headerMobileImage = headerBanner?.translations?.mn_MN?.mobileImageName
  const headerUrl = headerBanner?.translations?.mn_MN?.url

  return headerImg ? (
    <Link href={headerUrl || '/'}>
      <img
        src={
          process.env.BASE_IMAGE_BANNER_URL +
          (isMobile ? headerMobileImage : headerImg)
        }
        alt="gatsuurhan-header"
        className="w-100 h-auto mx-auto"
        style={{ marginBottom: '-50px' }}
      />
    </Link>
  ) : null
}

export default GatsuurhanHeader
