import { useEffect, useState } from 'react'

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

// import LongBanner from 'components/home/longBanner'
import MainSlide from 'components/home/mainSlide'
import SearchBar from 'components/global/searchBar'

import MostmoneyLogin from 'components/home/mostmoneyLogin'
import ProductCollection from 'components/home/productCollection'
import HiglightCategory from 'components/home/higlightCategory'
import Brand from 'components/home/brand'
import Banner from 'components/core/components/Banner'
import MobileCategoryBanner from 'components/home/mobileCategoryBanner'
import LendPromotion from 'components/home/lendPromotion'
// import BlackFridayPromotion from 'components/home/blackFridayPromotion/BlackFridayPromotion'
import NewYearPromotion from 'components/home/newYearPromotion'
import Link from 'next/link'
import SecondaryBanner from 'components/home/secondaryBanner'
import TravelBanners from 'components/home/travelBanners'
import CashBack from 'components/home/cash-back'
import BlackFriday from 'components/home/black-friday-2024'
import GatsuurhanHeader from 'components/home/gatsuurhan/header'

const FourBanner = dynamic(() => import('components/home/fourBanner'), {
  ssr: false,
})
const ShowroomMenu = dynamic(() => import('components/home/showroom-menu'), {
  ssr: false,
})
// const LongBanner = dynamic(() => import('components/home/longBanner'), { ssr: false })
const SalesBanner = dynamic(() => import('components/home/salesBanner'), {
  ssr: false,
})
const BananaOps = dynamic(() => import('components/home/bananaOps'), {
  ssr: false,
})
const ViewUsers = dynamic(() => import('components/global/viewUsers'), {
  ssr: false,
})

// loanBanner,
//   scentBanner,
//   mainCategoryBanner,
//   featuredBrands,
//   brandBanner,
//   stories,
//   threeBanners,
//   wideBanners,
//   twoSquareBanners1,
//   twoSquareBanners2,
//   salesBanners,
//   fourMobileBanners,
//   blackFridayProducts,
//   newYearImages,
//   newYearBackgroundImages,
//   newYearTextImages,
function Home({
  fourBanner,
  brandBannerTech,
  brandBannerBeau,
  mainBanner,
  travelBanners,
  longBanners,
  lendPromotionBanners,
  showroomTaxonBanners,
  blackFridayBackgrounds,
  category,
  specialSaleProducts,
  homeCollections,
  justmoveCashback,
}) {
  const [storyIndex, setStoryIndex] = useState(0)
  const [storyOpen, setStoryOpen] = useState(false)
  const router = useRouter()
  const exitClick = () => {
    setStoryOpen(false)
    document.body.classList.remove('activate-story-window')
  }
  useEffect(() => {
    if (document.body.className === 'user-menu-active') {
      document.body.classList.remove('user-menu-active')
      router.push('/')
    }
  }, [])

  return (
    <div className="home">
      {/*Утасны хайлт*/}
      <section className="mobile-search d-block d-lg-none ">
        <div className="container">
          <SearchBar mobile={true} />
        </div>
      </section>

      {/*Утасны хайлт*/}
      <div className="d-flex flex-column">
        <MainSlide mainSlides={mainBanner} />
      </div>

      {blackFridayBackgrounds && (
        <SecondaryBanner backgroundBanners={blackFridayBackgrounds} />
      )}
      <div className="mt-4" />
      {lendPromotionBanners && lendPromotionBanners.length > 0 ? (
        <LendPromotion
          banners={lendPromotionBanners}
          category={category}
          backgroundBanners={blackFridayBackgrounds}
        />
      ) : null}

      {/* Black Friday Promotion */}
      {/* <BlackFridayPromotion
        backgroundBanners={blackFridayBackgrounds}
        productBanners={blackFridayProducts}
      /> */}
      {/* <NewYearPromotion images={newYearImages} backgroundImages={newYearBackgroundImages} textImages={newYearTextImages}/> */}
      {/* <MobileCategoryBanner fourMobileBanners={fourMobileBanners} /> */}
      <GatsuurhanHeader data={justmoveCashback} />
      <ProductCollection
        homeCollections={homeCollections}
        code={'Gatsuurkhan_88'}
        isTitle={false}
        // unusualLink="/discount"
        // subTitle={'Бүх бүтээгдэхүүн харах'}
      />

      <TravelBanners travelBanners={travelBanners} />
      <ProductCollection
        unusualProducts={specialSaleProducts}
        unusualLink="/discount"
        unusualTitle={'ОНЦЛОХ ХЯМДРАЛ'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
      />
      {/* <BlackFriday travelBanners={travelBanners} banners={justmoveCashback} /> */}
      {/* <CashBack justmoveCashbackBanners={justmoveCashback} /> */}
      <HiglightCategory title={'АНГИЛАЛ'} subTitle={'Бүх ангилал харах'} />

      <ShowroomMenu banners={showroomTaxonBanners} />
      <div className="mt-5">
        <Brand
          brands={brandBannerTech || []}
          isColumn={false}
          title={'ТЕХНОЛОГИЙН брэндүүд'.toUpperCase()}
          subTitle={'Бүх брэнд харах '}
          showArrow={false}
        />
      </div>

      <div className="mt-4" />
      <BananaOps />
      <div className="mt-5" />
      <Brand
        brands={brandBannerBeau || []}
        isColumn={false}
        title={'ГОО САЙХНЫ брэндҮҮД'.toUpperCase()}
        subTitle={'Бүх брэнд харах '}
        showArrow={false}
      />
      <MostmoneyLogin />
      <div className="mt-5" />
      <Banner banner={longBanners && longBanners[0]} />
      <div className="mt-5" />
      {/* {
        loanBanner ? <LoanBanner loanBanner={loanBanner && loanBanner} /> : null
      } */}
      {/* <div className="new-product-list">
        <div className="container ">
          <div className="px-md-4 mx-md-4 ">
            <div className="d-flex p-4 showroom-background-image justify-content-between">
              <img
                className="w-50 my-3 my-md-4"
                src={'/static/images/showroom/text-logo-3.webp'}
              />
              <Link href={'https://showroom.bananamall.mn'}>
                <img
                  className="w-25 my-3 my-md-4 shop-now "
                  src={'/static/images/showroom/see-more.webp'}
                />
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <ProductCollection
        homeCollections={homeCollections}
        code={'collection_011'}
        unusualTitle={'ухаалаг утас'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
      />
      <Banner banner={longBanners && longBanners[1]} />

      <ProductCollection
        homeCollections={homeCollections}
        code={'221'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
      />

      <Banner banner={longBanners && longBanners[2]} />

      <div className="mt-4" />
      <ProductCollection
        homeCollections={homeCollections}
        code={'collection_002'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
      />
      <ProductCollection
        homeCollections={homeCollections}
        code={'collection_888'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
      />
      <div className="mt-5" />
      <FourBanner
        background={fourBanner && fourBanner[fourBanner.length - 1]}
        four1={fourBanner && fourBanner[0]}
        four2={fourBanner && fourBanner[1]}
        four3={fourBanner && fourBanner[2]}
        four4={fourBanner && fourBanner[3]}
      />

      <ProductCollection
        homeCollections={homeCollections}
        code={'collection_025'}
        unusualTitle={'Гоо сайхан'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
      />
      <Banner banner={longBanners && longBanners[3]} />
      <ProductCollection
        homeCollections={homeCollections}
        code={'collection_001'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
      />
      <Banner banner={longBanners && longBanners[4]} />
      <ProductCollection
        homeCollections={homeCollections}
        code={'collection_024'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
      />
      <ViewUsers />
    </div>
  )
}

export default Home
