import Image from 'next/image'
import useMediaQuery from 'components/hooks/useMediaQuery'
import ViewTitle from 'components/core/components/TextSection'
import ProductItem from 'components/core/components/product/productItem'

import { chunk } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import ProductBoxLoading from 'components/loading/productBoxLoading'

SwiperCore.use([Navigation, Pagination, Autoplay])
export default function ProductCollection({
  isTitle = true,
  unusualProducts = null,
  unusualLink = null,
  unusualTitle = null,
  subTitle,
  homeCollections,
  code,
  showPagination = true,
}) {
  const isMobileSize = useMediaQuery('(max-width: 638px)')
  const [loading, setLoading] = useState(true)

  const collectionData =
    homeCollections?.find((collection) => collection?.code === code) || {}

  const title = unusualTitle ? unusualTitle : collectionData?.name

  const products = unusualProducts
    ? unusualProducts
    : collectionData?.products || []

  const link = unusualLink
    ? unusualLink
    : collectionData?.slug + `?code=${collectionData?.code}`

  const getProducts = useMemo(() => {
    if (isMobileSize) {
      return chunk(products, products.length / 2)
    }
    return products
  }, [isMobileSize, products])

  useEffect(() => {
    if (getProducts) {
      setLoading(false)
    }
  }, [getProducts])

  return (
    <div className="new-product-list mt-5">
      {isTitle && (
        <ViewTitle
          title={title ? title.toUpperCase() : ''}
          subTitle={subTitle}
          link={link || '#'}
        />
      )}
      <div className="container px-4 box-sizing position-relative overflow-hidden">
        <div className="justify-content-center px-lg-3 mx-lg-2 pb-4">
          <div className="px-0 position-relative mt-4">
            {!loading && (
              <>
                <Swiper
                  className={isMobileSize ? '' : 'pb-4'}
                  slidesPerView={2}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  initialSlide={0}
                  // enabled={false}
                  grabCursor={true}
                  pagination={{
                    bulletActiveClas: 'p-bullet-active',
                    bulletClass: 'p-bullet',
                    el: '.p-slider-pagination',
                    clickable: true,
                  }}
                  navigation={{
                    nextEl: '.product-slider-next-button',
                    prevEl: '.product-slider-prev-button',
                  }}
                  loop={true}
                  breakpoints={{
                    639: {
                      slidesPerView: 3,
                      spaceBetween: 2,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 5,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1200: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                  }}
                >
                  <div className="swiper-wrapper px-md-4 mx-md-4 box-sizing">
                    {isMobileSize
                      ? getProducts &&
                        getProducts[0] &&
                        (getProducts[0] || []).map((product, index) => (
                          <div className="position-relative" key={index}>
                            <SwiperSlide
                              className="product-swiper-slide"
                              key={`slide_${index}`}
                            >
                              <div
                                className="px-2 py-2"
                                style={{ height: '100%' }}
                              >
                                <ProductItem product={product} />
                              </div>
                            </SwiperSlide>
                          </div>
                        ))
                      : getProducts &&
                        (getProducts || []).map((product, index) => (
                          <div className="position-relative" key={index}>
                            <SwiperSlide
                              className="product-swiper-slide"
                              key={`slide_${index}`}
                            >
                              <div
                                className="px-2 py-2"
                                style={{ height: '100%' }}
                              >
                                <ProductItem product={product} />
                              </div>
                            </SwiperSlide>
                          </div>
                        ))}
                  </div>
                  <div
                    className="swiper-pagination p-slider-pagination hide-pagination"
                    style={{ bottom: 0 }}
                  />
                </Swiper>

                {isMobileSize ? (
                  <Swiper
                    className="pb-4"
                    slidesPerView={2}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    grabCursor={true}
                    pagination={{
                      bulletActiveClas: 'p-bullet-active',
                      bulletClass: 'p-bullet',
                      el: '.p-slider-pagination',
                      clickable: true,
                    }}
                    navigation={{
                      nextEl: '.product-slider-next-button',
                      prevEl: '.product-slider-prev-button',
                    }}
                    loop={true}
                    breakpoints={{
                      // 640: {
                      //   slidesPerView: 3,
                      // },
                      639: {
                        slidesPerView: 3,
                        spaceBetween: 2,
                      },
                      768: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      1200: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    <div className="swiper-wrapper px-md-4 mx-md-4 box-sizing">
                      {getProducts &&
                        getProducts[1] &&
                        (getProducts[1] || []).map((product, index) => (
                          <div className="position-relative" key={index}>
                            <SwiperSlide className="" key={`slide_${index}`}>
                              <div
                                className="px-2 py-2"
                                style={{ height: '100%' }}
                              >
                                <ProductItem product={product} />
                              </div>
                            </SwiperSlide>
                          </div>
                        ))}
                    </div>

                    <div
                      className="swiper-pagination p-slider-pagination"
                      style={{ bottom: 0 }}
                    />
                  </Swiper>
                ) : null}
              </>
            )}

            {loading && (
              <>
                <div className="row d-none d-xl-flex">
                  {Array.from(Array(5)).map((val, ind) => {
                    return (
                      <div className="col" key={ind}>
                        <ProductBoxLoading width={'100%'} />
                      </div>
                    )
                  })}
                </div>
                <div className="row d-none d-md-flex d-xl-none">
                  {Array.from(Array(4)).map((val, ind) => {
                    return (
                      <div className="col" key={ind}>
                        <ProductBoxLoading width={'100%'} />
                      </div>
                    )
                  })}
                </div>
                <div className="row d-md-none">
                  {Array.from(Array(2)).map((val, ind) => {
                    return (
                      <div className="col" key={ind}>
                        <ProductBoxLoading width={'100%'} />
                      </div>
                    )
                  })}
                </div>
              </>
            )}
          </div>

          <div
            className="swiper-button-prev product-slider-prev-button"
            style={{ left: 0 }}
          />
          <div
            className="swiper-button-next product-slider-next-button"
            style={{ right: 0 }}
          />
        </div>
      </div>
    </div>
  )
}
